import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const productPlanSlice = createSlice({
    name: "ProductPlanReducer",
    initialState: { pageNo: 1, pageSize: 10, searchParam: null },
    reducers: {
        setProductPlanPageNo: (state, action: PayloadAction<number>) => {
            state.pageNo = action.payload;
        },
        setProductPlanPageSize: (state, action: PayloadAction<number>) => {
            state.pageSize = action.payload;
        },
        setProductPlanSearchParam: (state, action: PayloadAction<any>) => {
            state.searchParam = action.payload;
        }
    }
});

export const { setProductPlanPageNo, setProductPlanPageSize, setProductPlanSearchParam } = productPlanSlice.actions;
export default productPlanSlice.reducer;