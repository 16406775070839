import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Space,
  Switch,
  Table,
} from "antd";
import iconSearch from "../../../assets/images/search.svg";
import { ColumnsType } from "antd/es/table";
import AddProductPlan from "./AddProductPlan";
import { useDispatch, useSelector } from "react-redux";
import { setProductPageNo } from "../../../shared/redux/product-reducer";
import {
  setProductPlanPageNo,
  setProductPlanPageSize,
  setProductPlanSearchParam,
} from "../../../shared/redux/product-plan-reducer";
import { RootState } from "../../../shared/redux/store";
import iconDelete from "../../../assets/images/delete.svg";
import "../ProductPlan/ProductPlan.scss";
import { API_SERVICE } from "../../../shared/services/api-services";
import iconEdit from "../../../assets/images/edit.svg";

interface columns {
  key: React.ReactNode;
  name: string;
  description: string;
  id: number;
}

interface IProductPlan {}

const ProductPlan: React.FC<IProductPlan> = (props: IProductPlan) => {
  const {} = props;
  const [isAddProductPlan, setAddProductPlan] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [productPlanList, setProductPlanList] = useState([] as any);
  const productPlan: any = useSelector((state: RootState) => state.productPlan);
  const [searchProductPlanForm] = Form.useForm() as any;
  const [editProductPlanData, setEditProductPlanData] = useState(null as any);
  const dispatch = useDispatch();

  useEffect(() => {
    getProductPlanList(
      productPlan.pageNo,
      productPlan.pageSize,
      productPlan.searchParam
    );
    searchProductPlanForm.setFieldsValue({
      searchParam: productPlan.searchParam ?? "",
    });
  }, []);

  const getProductPlanList = (
    pageNo: number = 1,
    pageSize: any = 10,
    searchVal: any = null
  ) => {
    setLoading(true);
    const params = {
      pageNo: pageNo,
      pageSize: pageSize,
      nameFilter: searchVal,
    };
    API_SERVICE.fetchProductPlanList(params)
      .then(({ data }: any) => {
        setProductPlanList(data?.payload?.content ?? []);
        setTotalCount(data?.payload?.totalElements ?? 0);
      })
      .catch((e: any) => API_SERVICE.handleErrors(e))
      .finally(() => setLoading(false));
  };

  const handleSearch = (value: any) => {
    dispatch(setProductPlanPageNo(1));
    dispatch(setProductPlanSearchParam(value?.searchParam));
    getProductPlanList(1, productPlan.pageSize, value?.searchParam);
  };

  const handlePaginationChange = (current: any, pageSize: any) => {
    dispatch(setProductPlanPageNo(current));
    dispatch(setProductPlanPageSize(pageSize));
    getProductPlanList(current, pageSize, productPlan.searchParam);
  };

  const actionEdit = (record: any) => {
    setIsEdit(true);
    setAddProductPlan(true);
    setEditProductPlanData(record);
    // API_SERVICE.getEditProductPlanData(record?.productPlanId)
    //   .then(({ data }: any) => {
    //   })
    //   .catch((e: any) => {
    //     API_SERVICE.handleErrors(e);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

  const handleDelete = (id: any) => {
    API_SERVICE.deleteProductPlanById(id)
      .then(() => {
        getProductPlanList(productPlan.pageNo, productPlan.pageSize, productPlan.searchParam);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  };

  const updateStatus = (planId: string, status: boolean) => {
    setLoading(true);
    API_SERVICE.updateProductPlanStatus(planId, status ? "ACTIVE" : "INACTIVE")
      .then(({ data }) => {
        const tmpLender = productPlanList.map((plan: any) => {
          if (plan.productPlanId == planId) {
            return { ...plan, status: status ? "ACTIVE" : "INACTIVE" };
          }
          return plan;
        });
        setProductPlanList(tmpLender);
      })
      .catch((e: any) => API_SERVICE.handleErrors(e))
      .finally(() => setLoading(false));
  };

  const columns: ColumnsType<columns> = [
    {
      title: "Product Plan Name",
      dataIndex: "productPlanName",
      key: "productPlanName",
    },
    {
      title: "Product",
      dataIndex: "productTypeName",
      key: "productTypeName",
    },
    {
      title: "Provider",
      dataIndex: "providerName",
      key: "providerName",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (_: any, record: any) => (
        <Switch
          checked={record.status == "ACTIVE"}
          onChange={(value) => {
            updateStatus(record.productPlanId, value);
          }}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (_: any, record: any) => {
        return (
          <>
            <Space>
              <span
                onClick={() => {
                  actionEdit(record);
                }}
                style={{ cursor: "pointer" }}
              >
                {<img src={iconEdit} />}
              </span>
              <Popconfirm
                placement="top"
                title="Are you sure to delete?"
                okText="Yes"
                cancelText="No"
                className="remove"
                onConfirm={() => handleDelete(record?.productPlanId)}
              >
                <Button
                  className="deleteButton"
                  size="small"
                  icon={<img src={iconDelete} height={25} width={25} />}
                ></Button>
              </Popconfirm>
            </Space>
          </>
        );
      },
    },
  ];

  const renderProductPlanList = () => {
    return (
      <>
        <div
          className={"layout-main-top"}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Form
            layout="inline"
            form={searchProductPlanForm}
            onFinish={handleSearch}
          >
            <Form.Item name="searchParam">
              <Input
                size="large"
                className="search-input"
                prefix={<img src={iconSearch} alt="" />}
                placeholder="Search by name"
                allowClear
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                style={{ height: "40px" }}
                htmlType="submit"
              >
                Search
              </Button>
            </Form.Item>
          </Form>
          <div>
            <Button
              onClick={() => {
                setIsEdit(false);
                setAddProductPlan(true);
              }}
              type="primary"
              style={{ height: "40px" }}
            >
              Add Product Plan
            </Button>
          </div>
        </div>

        <div className={"layout-main"}>
          <Row style={{ padding: "15px" }}>
            <Col xs={24} xl={24} span={13}>
              <Table
                columns={columns}
                dataSource={productPlanList}
                size="middle"
                loading={loading}
                onChange={(pagination: any) => {
                  handlePaginationChange(
                    pagination.current,
                    pagination.pageSize
                  );
                }}
                pagination={{
                  position: ["bottomCenter"],
                  current: productPlan.pageNo,
                  pageSize: productPlan.pageSize,
                  total: totalCount,
                }}
                scroll={{ x: "max-content" }}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  };

  return (
    <>
      {isAddProductPlan ? (
        <AddProductPlan
          setAddProductPlan={setAddProductPlan}
          isEdit={isEdit}
          getProductPlanList={getProductPlanList}
          editProductPlanData={editProductPlanData}
        />
      ) : (
        renderProductPlanList()
      )}
    </>
  );
};

export default ProductPlan;
