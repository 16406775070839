import { Button, Divider, Form, Modal, Select, Tabs, Radio, Input } from "antd";
import React, { useEffect, useState } from "react";
import { MdOutlineEdit } from "../../../../node_modules/react-icons/md";
import editIcon from "../../../assets/images/edit.svg";
import "../ProductJourney/ProductJourney.scss";
import { API_SERVICE } from "../../../shared/services/api-services";
import { getEnumList, getEnumValue } from "../../../shared/Utility/Utility";
import { viewTypeProduct } from "../Products/Products";
import AppLoading from "../../../shared/component/AppLoading/AppLoading";
import FormButton from "../../../shared/component/FormButton/FormButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../shared/redux/store";
import {
  setJourneyObject,
  setScreenTitle,
} from "../../../shared/redux/common-reducer";
import JourneyStepNew from "./JourneyStepNew";
import JourneyFooterNew from "./JourneyFooterNew";
import DocumentUpload from "../ProductJourney/DocumentUpload";
import { viewTypeJourneyProduct } from "./Journey";
import FormInput from "../../../shared/component/FormInput/FormInput";
import DecisionModel from "./DecisionModel";

type IJourneyDetails = {
  productWorkflowId: any;
  setViewTypeJourney: React.Dispatch<
    React.SetStateAction<viewTypeJourneyProduct>
  >;
  masterproductId?: any;
  fetchMasterProductList: any;
};

const JourneyDetails: React.FunctionComponent<IJourneyDetails> = (
  props: IJourneyDetails
) => {
  const {
    productWorkflowId,
    setViewTypeJourney,
    masterproductId,
    fetchMasterProductList,
  } = props;
  const [runTypeStage, setRunTypeStage] = useState(null as any);
  const [journeyData, setJourneyData] = useState({} as any);
  const [vasData, setVasData] = useState([] as any);
  const [decisioningData, setDecisioningData] = useState([] as any);
  const [selectedStage, setSelectedStage] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [isDecisionModal, SetIsDecisionModal] = useState(false);
  const [currentStage, setCurrentStage] = useState("");
  const [stageIndex, setStageIndex] = useState(0);
  const [stepsIndex, setStepsIndex] = useState(0);
  const [currentSteps, setCurrentSteps] = useState("");
  const [decisioningOptions, setDecisioningOptions] = useState([] as any);
  const [displayConfigDecisionList, setDisplayConfigDecisionList] = useState(
    [] as any
  );
  const [configValue, setConfigValue] = useState("");
  const [configResultVal, setConfigResultVal] = useState(true);
  const [vasList, setVasList] = useState([] as any);
  const [decisioningList, setDecisioningList] = useState([] as any);
  const [isSaveDisable, setIsSaveDisable] = useState(false);
  const [isEditStepName, setIsEditStepName] = useState(false);
  const [isEditStageName, setIsEditStageName] = useState(false);
  const dispatch = useDispatch();
  const { productsObject } = useSelector((state: RootState) => state.common);
  const product: any = useSelector((state: RootState) => state.product);
  const journey: any = useSelector((state: RootState) => state.journey);

  const [nextAssistedJourney, setNextAssistedJourney] = useState([] as any);

  useEffect(() => {
    getProductWorkflowById();
    getDecisioningList();
    getNextAssistedJourneyDDl();
  }, []);

  const getProductWorkflowById = () => {
    setLoading(true);
    API_SERVICE.getProductWorkflowById(productWorkflowId)
      .then(({ data }) => {
        setJourneyData(data?.payload?.productWorkflowConfig);
        const firstStage =
          data?.payload?.productWorkflowConfig?.stages?.at(0) ?? [];
        tabOnChange(firstStage?.key, data?.payload?.productWorkflowConfig);
        setCurrentStage(firstStage?.key);
        onchangeProspect(firstStage?.steps?.at(0)?.key, firstStage);
        setCurrentSteps(firstStage?.steps?.at(0)?.key);
        setRunTypeStage(
          getEnumValue("StepConfigValue", firstStage?.runType?.isEnabled)
        );
        setConfigValue(firstStage?.runType?.stepDecisioningId);
        setConfigResultVal(firstStage?.runType?.stepDecisioningResult !== null ? firstStage?.runType?.stepDecisioningResult : true)
      })
      .catch((e: any) => API_SERVICE.handleErrors(e))
      .finally(() => {
        setLoading(false);
      });
  };

  const getDecisioningList = () => {
    let params: any = {
      pageSize: 100,
      isPublished: "true",
      decisionTypes: "SCORECARD, DECISION_TABLE, STAGE_CONFIG, OFFER_DECISION",
      masterProductFilter: productsObject?.productsId,
    };
    API_SERVICE.getDecisioningList(params)
      .then(({ data }) => {
        const configFilter = data?.payload?.content?.filter(
          (item: any) => item?.decisionType == "STAGE_CONFIG"
        );
        const postFilter = data?.payload?.content?.filter(
          (item: any) => item?.decisionType !== "STAGE_CONFIG"
        );
        setDecisioningOptions(postFilter);
        getProductActionList(
          productsObject?.productsId,
          postFilter,
          configFilter
        );
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const getNextAssistedJourneyDDl = () => {
    API_SERVICE.getAssistedJourneyDdlData(masterproductId).then(({ data }) => {
      if (data?.payload) {
        const arrayData: any = [];
        if (data?.payload?.productWorkflowConfig) {
          data?.payload?.productWorkflowConfig?.stages?.map((item: any) => {
            if (item?.runType?.isEnabled !== "FALSE") {
              arrayData.push({
                key: item?.key,
                value: item?.name
              });
              item?.steps?.filter((stepItems: any) => {
                if (stepItems?.runType?.isEnabled !== "FALSE") {
                  arrayData.push({
                    key: stepItems?.key,
                    value: stepItems?.name
                  });
                }
              })
            }
          })
        }
        setNextAssistedJourney([...arrayData]);
      }
    }).catch((error) => {
      API_SERVICE.handleErrors(error)
    });
  }

  const getProductActionList = (
    id: any,
    tmpDecisionOption: any = decisioningOptions,
    tmpConfigList: any
  ) => {
    setLoading(true);
    API_SERVICE.getProductActionList(id)
      .then(({ data }: any) => {
        const vasList = data?.payload?.filter(
          (item: any) => item?.category == "VAS" && item?.status == "ACTIVE"
        );
        setVasList(vasList);

        const deciList = data?.payload?.filter(
          (item: any) =>
            item?.category == "DECISIONING" && item?.status == "ACTIVE"
        );
        const tmpDecisioningList = tmpDecisionOption?.map((item: any) => {
          const findList = deciList?.find((deOption: any) => {
            return deOption?.id == item?.id;
          });

          for (let key in findList) {
            if (findList && findList[key] != undefined) {
              return {
                key: findList?.id,
                value: findList?.name,
              };
            } else {
              delete findList[key];
            }
          }
        });
        let filteredTmpDecisioningList = tmpDecisioningList.filter(function (
          el: any
        ) {
          return el != null;
        });

        setDecisioningList(filteredTmpDecisioningList);
        const configList = tmpConfigList?.map((item: any) => {
          const findConfig = deciList?.find((deOption: any) => {
            return deOption?.id == item?.id;
          });

          for (let key in findConfig) {
            if (findConfig && findConfig[key] != undefined) {
              return {
                value: findConfig?.id,
                label: findConfig?.name,
              };
            } else {
              delete findConfig[key];
            }
          }
        });
        let filteredConfigList = configList.filter(function (el: any) {
          return el != null;
        });
        setDisplayConfigDecisionList(filteredConfigList);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onStageRunType = (key: any) => {
    setRunTypeStage(key);
    const tmpJourney = { ...journeyData };
    if ((journeyData?.stages?.length ?? 0) > 0) {
      if (tmpJourney?.stages[stageIndex]?.runType == null) {
        tmpJourney.stages[stageIndex].runType = {
          isEnabled: key,
        };
      } else {
        tmpJourney.stages[stageIndex].runType.isEnabled = key;
      }
      //   setJourneyData(tmpJourney);
    }
    if (key === "CONDITIONAL") {
      SetIsDecisionModal(true);
    }
  };

  const onConfigDecision = () => {
    const tmpJourney = { ...journeyData };
    tmpJourney.stages[stageIndex].runType.stepDecisioningId = configValue;
    tmpJourney.stages[stageIndex].runType.stepDecisioningResult = configResultVal;
    // setJourneyData(tmpJourney);
    SetIsDecisionModal(false);
  };

  const addSelectBox = (
    <div className="add-select">
      <Button
        type="link"
        onClick={() => {
          setViewTypeJourney("journey");
          dispatch(setScreenTitle("Products Journeys"));
          dispatch(setJourneyObject({}));
          fetchMasterProductList(
            journey?.pageNo,
            journey?.pageSize,
            journey.searchParam
          );
        }}
      >
        Back to Journey
      </Button>
      <span>Run Type:</span>
      <Select
        value={runTypeStage}
        disabled={
          journeyData?.stages?.at(stageIndex)?.journeyBuilderConfiguration
            ?.stepRunTypeDisabled
        }
        onChange={onStageRunType}
        style={{
          width: "150px",
          borderRadius: "6px",
          border: "1px solid gray",
        }}
      >
        {getEnumList("StepConfigValue")?.map((option: any, i: number) => (
          <Select.Option key={i} value={option.key}>
            {option.value}
          </Select.Option>
        ))}
      </Select>

      {runTypeStage?.toUpperCase() === "CONDITIONAL" && (
        <img src={editIcon} onClick={() => SetIsDecisionModal(true)} />
      )}
    </div>
  );

  const onchangeProspect = (key: any, item: any) => {
    setCurrentSteps(key);
    let demo = item?.steps?.find((item: any) => {
      return item?.key == key;
    });
    const getIndex = item?.steps?.findIndex((item: any) => item?.key == key);
    setStepsIndex(getIndex ?? 0);
    setVasData(demo?.vas ?? []);
    setDecisioningData(demo?.decisioning ?? []);
  };

  const renderTabEdit = (tab: any, isStep: boolean) => {
    const onFinish = (value: any) => {
      if (isStep) {
        const tmpJourney = { ...journeyData };
        tmpJourney.stages[stageIndex].steps[stepsIndex].name = value?.tabName
        setJourneyData(tmpJourney);
        setIsEditStepName(false);
      } else {
        const tmpJourney = { ...journeyData };
        tmpJourney.stages[stageIndex].name = value?.tabName
        setJourneyData(tmpJourney);
        setIsEditStageName(false);
      }
    }
    return (
      <Modal
        title={isStep ? "Edit Step Name" : "Edit Stage Name"}
        visible={isStep ? isEditStepName : isEditStageName}
        closable={false}
        footer={false}
        width={280}
      >
        <Form layout="vertical" onFinish={(value) => onFinish(value)}>
          <FormInput
            name="tabName"
            initialValue={tab?.name}
            isBorder
            size="small"
            rules={[{
              required: true,
              message: `Please enter ${isStep ? "step" : "stage"} name`
            }
            ]}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: "35px",
            }}
          >
            <FormButton
              label="Cancel"
              onclick={() => { isStep ? setIsEditStepName(false) : setIsEditStageName(false) }}
              style={{ width: "100px", height: "40px" }}
            />
            <FormButton
              label="Save"
              type="primary"
              htmlType="submit"
              style={{ width: "100px", height: "40px" }}
            />

          </div>
        </Form>
      </Modal>
    )
  }

  const renderSteps = (item: any) => {
    return (
      <>
        <div className="pro-jou-tab12">
          <Tabs
            activeKey={currentSteps}
            onChange={(e) => {
              onchangeProspect(e, item);
            }}
          >
            {item?.steps?.map((item: any) => {
              return (
                <Tabs.TabPane
                  key={item?.key}
                  tab={<>
                    {item?.name}&nbsp;
                    {currentSteps == item?.key &&
                      (<MdOutlineEdit
                        style={{ fontSize: "18px", marginTop: "-3px" }}
                        onClick={() => setIsEditStepName(true)} />
                      )}
                  </>}
                  disabled={item?.disableConfiguration}
                >
                  {!item?.disableConfiguration && (
                    <JourneyStepNew
                      isStep={true}
                      step={item}
                      setJourneyData={setJourneyData}
                      journeyData={journeyData}
                      stageIndex={stageIndex}
                      stepsIndex={stepsIndex}
                      displayConfigDecisionList={displayConfigDecisionList}
                      setIsSaveDisable={setIsSaveDisable}
                      nextAssistedJourney={nextAssistedJourney}
                      vasData={(selectedStage?.steps?.length ?? 0) > 0
                        ? vasData
                        : selectedStage?.vas}
                      vasList={vasList}
                      setVasData={setVasData}
                      productWorkflowId={productWorkflowId}
                      decisioningData={decisioningData}
                      decisioningOptions={decisioningOptions}
                      getProductWorkflowById={getProductWorkflowById}
                      isSaveDisable={isSaveDisable}
                      decisioningList={decisioningList}
                      setViewTypeJourney={setViewTypeJourney}
                    />
                  )}
                  {isEditStepName && currentSteps == item?.key ? renderTabEdit(item, true) : ""}
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        </div>
      </>
    );
  };

  const tabOnChange = (key: any, journeyData: any) => {
    // setJourneyData(journeyData);
    setCurrentStage(key);
    const selectedStageTmp = journeyData?.stages?.find((item: any) => {
      return item?.key == key;
    });
    if ((selectedStageTmp?.steps?.length ?? 0) > 0) {
      for (let index = 0; index < selectedStageTmp?.steps.length; index++) {
        const stepkey = selectedStageTmp?.steps[index];
        if (!stepkey?.journeyBuilderConfiguration?.disableConfiguration) {
          setCurrentSteps(stepkey?.key);
          onchangeProspect(stepkey?.key, selectedStageTmp);
          break;
        }
      }
    } else {
      setVasData(selectedStageTmp?.vas ?? []);
      setDecisioningData(selectedStageTmp?.decisioning ?? []);
      setIsSaveDisable(false);
    }
    const getIndex = journeyData?.stages?.findIndex(
      (item: any) => item?.key == key
    );
    setStageIndex(getIndex);
    console.log('Stages', journeyData?.stages);
    console.log('Stages', getIndex);
    setConfigValue(journeyData?.stages[getIndex]?.runType?.stepDecisioningId);
    setConfigResultVal(journeyData?.stages[getIndex]?.runType?.stepDecisioningResult !== null ? journeyData?.stages[getIndex]?.runType?.stepDecisioningResult : true);
    setSelectedStage(selectedStageTmp ?? {});
    setRunTypeStage(
      getEnumValue("StepConfigValue", selectedStageTmp?.runType?.isEnabled)
    );
  };

  const renderProductProperty = () => {
    return (
      <>
        <div className={"layout-main"} style={{ marginTop: "20px" }}>
          <div>
            <Tabs
              activeKey={currentStage}
              tabBarExtraContent={addSelectBox}
              className="pro-jou-tab"
              onChange={(key: any) => tabOnChange(key, journeyData)}
            >
              {journeyData?.stages?.map((tab: any) => {
                return (
                  <Tabs.TabPane
                    key={tab.key}
                    tab={<>
                      {tab?.name}&nbsp;
                      {currentStage == tab?.key && (
                        <MdOutlineEdit
                          style={{ fontSize: "18px", marginTop: "-3px" }}
                          onClick={() => setIsEditStageName(true)}
                        />)}
                    </>}
                    // disabled={tab?.journeyBuilderConfiguration?.disableConfiguration}
                  >
                    {(tab?.steps?.length ?? 0) > 0 ? (
                      renderSteps(tab)
                    ) : tab?.journeyBuilderConfiguration?.showDocumentList ? (
                      <>
                        {/* <DocumentUpload /> */}
                        <JourneyFooterNew
                          productWorkflowId={productWorkflowId}
                          isStep={false}
                          decisioningData={decisioningData}
                          decisioningOptions={decisioningOptions}
                          journeyData={journeyData}
                          setJourneyData={setJourneyData}
                          stageIndex={stageIndex}
                          stepsIndex={stepsIndex}
                          step={[]}
                          stage={tab}
                          decisioningList={decisioningList}
                          getProductWorkflowById={getProductWorkflowById}
                          displayConfigDecisionList={displayConfigDecisionList}
                          nextAssistedJourney={nextAssistedJourney}
                          vasList={vasList}
                          vasData={vasData}
                          setVasData={setVasData}
                          isSaveDisable={isSaveDisable}
                          setViewTypeJourney={setViewTypeJourney} />
                      </>
                    ) : (
                      <JourneyStepNew
                        isStep={false}
                        step={[]}
                        stage={tab}
                        setJourneyData={setJourneyData}
                        journeyData={journeyData}
                        stageIndex={stageIndex}
                        stepsIndex={stepsIndex}
                        displayConfigDecisionList={displayConfigDecisionList}
                        setIsSaveDisable={setIsSaveDisable}
                        nextAssistedJourney={nextAssistedJourney}
                        vasData={(selectedStage?.steps?.length ?? 0) > 0
                          ? vasData
                          : selectedStage?.vas}
                        vasList={vasList}
                        setVasData={setVasData}
                        productWorkflowId={productWorkflowId}
                        decisioningData={decisioningData}
                        decisioningOptions={decisioningOptions}
                        getProductWorkflowById={getProductWorkflowById}
                        isSaveDisable={isSaveDisable}
                        decisioningList={decisioningList}
                        setViewTypeJourney={setViewTypeJourney}
                      />
                    )}
                    {isEditStageName && currentStage == tab?.key ? renderTabEdit(tab, false) : ""}
                  </Tabs.TabPane>
                );
              })}
            </Tabs>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {renderProductProperty()}
      {loading && <AppLoading />}
      {isDecisionModal && (
        <DecisionModel
          isDecisionModal={isDecisionModal}
          checkJourneyBuilderConfiguration={journeyData?.stages?.at(stageIndex)}
          displayConfigDecisionList={displayConfigDecisionList}
          configValue={configValue}
          configResultVal={configResultVal}
          cancelClick={() => {
            const tmpJourney = { ...journeyData };
            setConfigValue(
              tmpJourney?.stages[stageIndex]?.runType?.stepDecisioningId
            );
            setConfigResultVal(
              tmpJourney?.stages[stageIndex]?.runType?.stepDecisioningResult !== null ? tmpJourney?.stages[stageIndex]?.runType?.stepDecisioningResult : true
            )
            tmpJourney.stages[stageIndex].runType.stepDecisioningId =
              tmpJourney?.stages[stageIndex]?.runType?.stepDecisioningId;
            tmpJourney.stages[stageIndex].runType.stepDecisioningResult =
              tmpJourney?.stages[stageIndex]?.runType?.stepDecisioningResult;
            // setJourneyData(tmpJourney);
            SetIsDecisionModal(false);
          }}
          saveClick={() => {
            const tmpJourney = { ...journeyData };
            tmpJourney.stages[stageIndex].runType.stepDecisioningId = configValue;
            tmpJourney.stages[stageIndex].runType.stepDecisioningResult = configResultVal;
            // setJourneyData(tmpJourney);
            SetIsDecisionModal(false);
          }}
          selectChange={(key: any) => setConfigValue(key)}
          radioChange={(e) => setConfigResultVal(e)} />
      )}
    </>
  );
};

export default JourneyDetails;
