import React, { useEffect, useState } from "react";
import { Form, Space, Typography, notification } from "antd";
import FormSelect from "../../../shared/component/FormSelect/FormSelect";
import { getEnumList } from "../../../shared/Utility/Utility";
import "../ProductPlan/ProductPlan.scss";
import FormInput from "../../../shared/component/FormInput/FormInput";
import FormTextArea from "../../../shared/component/FormTextArea/FormTextArea";
import FormButton from "../../../shared/component/FormButton/FormButton";
import { API_SERVICE } from "../../../shared/services/api-services";
import { useSelector } from "react-redux";
import { RootState } from "../../../shared/redux/store";

const { Title } = Typography;

interface IAddProductPlan {
  setAddProductPlan?: any;
  isEdit: any;
  getProductPlanList: any;
  editProductPlanData: any;
}

const AddProductPlan: React.FC<IAddProductPlan> = (props: IAddProductPlan) => {
  const { setAddProductPlan, isEdit, getProductPlanList, editProductPlanData } =
    props;

  const [addProductPlanForm] = Form.useForm() as any;
  const [productTypes, setProductTypes] = useState([] as any);
  const [providerList, setProviderList] = useState([] as any);
  const productPlan: any = useSelector((state: RootState) => state.productPlan);

  useEffect(() => {
    if (isEdit) {
      addProductPlanForm.setFieldsValue({
        description: editProductPlanData?.description,
        productPlanName: editProductPlanData?.productPlanName,
        productType: editProductPlanData?.productTypeName,
        provider: editProductPlanData?.providerId,
      });
    }
    listAllTheProviders();
    getAllLoanProductList();
  }, []);

  const listAllTheProviders = () => {
    API_SERVICE.getFiList({ pageSize: 500, financierCategory: "INSURER" }).then(
      ({ data }: any) => {
        const displayNameList = data?.payload?.content?.map((listVal: any) => {
          return {
            key: listVal?.fiId,
            value: listVal?.financierName,
          };
        });
        setProviderList(displayNameList);
        if (isEdit) {
          addProductPlanForm.setFieldsValue({
            provider: editProductPlanData?.providerId,
          });
        }
      }
    );
  };

  const getAllLoanProductList = () => {
    API_SERVICE.getAllLoanProduct()
      .then(({ data }: any) => {
        if (data?.payload) {
          const productListItems: any = data?.payload?.map((i: any) => {
            return {
              key: i?.loanProductId,
              value: i?.description,
            };
          });
          setProductTypes(productListItems);
          if (isEdit) {
            addProductPlanForm.setFieldsValue({
              productType: editProductPlanData?.productTypeId,
            });
          }
        }
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const onSubmit = (values: any) => {
    const payload = {
      description: values?.description,
      productPlanName: values?.productPlanName,
      productType: values?.productType,
      provider: values?.provider,
    };
    if (isEdit) {
      updateProductPlan(payload);
    } else {
      addProductPlan(payload);
    }
  };

  const addProductPlan = (payload: any) => {
    API_SERVICE.addProductPlan(payload)
      .then(({ data }) => {
        notification.success({ message: data?.payload });
        setAddProductPlan(false);
        getProductPlanList();
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const updateProductPlan = (payload: any) => {
    API_SERVICE.updateProductPlan(editProductPlanData?.productPlanId, payload)
      .then(({ data }) => {
        notification.success({ message: data?.payload });
        setAddProductPlan(false);
        getProductPlanList(
          productPlan.pageNo,
          productPlan.pageSize,
          productPlan.searchParam
        );
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  return (
    <div className={"layout-main"} style={{ marginTop: "15px" }}>
      <Title level={5} style={{ paddingTop: "17px", paddingInline: "30px" }}>
        {isEdit ? "Edit Product Plan" : "Add New Product Plan"}
      </Title>
      <div className="product-plan-form-layout ">
        <Form
          layout="vertical"
          form={addProductPlanForm}
          className="product-plan-form"
          onFinish={onSubmit}
        >
          <FormInput
            name="productPlanName"
            label="Product Plan Name"
            placeholder="Enter product plan name"
            isBorder
            isRequired
          />
          <FormSelect
            name="productType"
            label="Product Type"
            placeholder="Select product type"
            isBorder
            isRequired
            options={productTypes}
            optionFilterProp="children"
          />
          <FormSelect
            name="provider"
            label="Provider"
            placeholder="Select provider"
            isBorder
            isRequired
            options={providerList}
            optionFilterProp="children"
          />
          <FormTextArea
            name="description"
            label="Description"
            isBorder
            isRequired
            row={3}
          />
          <Space style={{ paddingTop: "20px" }}>
            <FormButton
              label="Cancel"
              onclick={() => {
                setAddProductPlan(false);
              }}
              style={{ height: "50px", width: "150px" }}
            />
            <FormButton
              label="Save"
              type="primary"
              htmlType="submit"
              style={{ height: "50px", width: "150px" }}
            />
          </Space>
        </Form>
      </div>
    </div>
  );
};

export default AddProductPlan;
