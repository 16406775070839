import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Switch, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import search from "../../assets/images/search.svg";
import { API_SERVICE } from '../../shared/services/api-services';
import { useDispatch, useSelector } from 'react-redux';
import { setTenantPageNo, setTenantPageSize, setTenantSearchParam } from '../../shared/redux/tenant-reducer';
import { RootState } from '../../shared/redux/store';
import AddTenantLogo from './AddTenantLogo';
import FormIconButton from '../../shared/component/FormIconButton/FormIconButton';

interface columns {
    key: React.ReactNode;
    name: string;
    logo: any;
}

const DcConguration: React.FC = () => {

    const [assignToDataList, setAssignToDataList] = useState([]);
    const [editData, setEditData] = useState([] as any);
    const [openUploaDrawer, setOpenUploaDrawer] = useState(false);
    const [loading, setLoading] = useState(false);
    const tenant: any = useSelector((state: RootState) => state.tenant);
    const [totalCount, setTotalCount] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setTenantPageNo(1));
        dispatch(setTenantPageSize(10));
        dispatch(setTenantSearchParam(""));
        getTenantList();
    }, []);

    const actionEdit = (record: any) => {
        setEditData(record)
        setOpenUploaDrawer(true)
    }

    const handlePaginationChange = (current: any, pageSize: any) => {
        dispatch(setTenantPageNo(current));
        dispatch(setTenantPageSize(pageSize));
        getTenantList(current, pageSize);
    };

    const handleSearch = (value: any) => {
        dispatch(setTenantPageNo(1));
        dispatch(setTenantSearchParam(value?.searchParam));
        getTenantList(1, tenant.pageSize, value?.searchParam);
    };

    const getTenantList = (
        pageNo: number = 1,
        pageSize: any = 10,
        searchParam: any = null
    ) => {
        setLoading(true);
        const params = {
            pageNo: pageNo,
            pageSize: pageSize,
            filterNameParam: searchParam
        };
        API_SERVICE.getListTenants(params)
            .then(({ data }) => {
                setAssignToDataList(data?.payload?.content ?? []);
                setTotalCount(data?.payload?.totalElements ?? 0);
            })
            .catch((e: any) => 
                API_SERVICE.handleErrors(e)
            )
            .finally(() => 
                setLoading(false)
            );
    };

    const updateTenant = (
        tenetId: string,
        params: any
    ) => {
        setLoading(true);
        API_SERVICE.updateTenant(tenetId, params)
            .then(({ data }) => {
                getTenantList(tenant.pageNo, tenant.pageSize, tenant.searchParam);
            })
            .catch((e: any) => 
                API_SERVICE.handleErrors(e)
            )
            .finally(() => 
                setLoading(false)
            );
    };

    const columns: ColumnsType<columns> = [
        {
            title: 'Tenant Name',
            dataIndex: "businessName",
            key: 'businessName',
        },
        {
            title: 'Logo',
            dataIndex: 'tenantLogoName',
            key: 'tenantLogoName',
        },
        {
            title: 'Tenant Type',
            dataIndex: 'businessProductType',
            key: 'businessProductType',
        },
        {
            title: 'Decisioning Audit',
            dataIndex: 'decisioningAudit',
            key: 'status',
            align: "center",
            render: (_: any, record: any) => (
                <Switch checked={record.showDecisioningAudit} onChange={(value) => {
                    const params = { showDecisioningAudit: value }
                    updateTenant(record.businessInfoId, params);
                }}/>
            ),
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            fixed: "right",
            align:'center',
            render: (_: any, record: any) => (
                <FormIconButton
                    type={"edit"}
                    onClick={() => actionEdit(record)}
                />
            ),
        },
    ];

    return (
        <>
            <div className="layout-main-top">
                <Form layout="inline"
                    onFinish={handleSearch}
                >
                    <Form.Item name='searchParam'>
                        <Input
                            size="large"
                            className="search-input"
                            prefix={<img src={search} />}
                            placeholder="Search"
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" style={{ height: '40px' }} htmlType="submit">Search</Button>
                    </Form.Item>
                </Form>
            </div>
            <div className={"layout-main"} >
                <Row style={{ padding: '15px' }}>
                    <Col xs={24} xl={18} span={18}>
                        <Table
                            columns={columns}
                            dataSource={assignToDataList}
                            size='middle'
                            loading={loading}
                            onChange={(pagination: any) => {
                                handlePaginationChange(pagination.current, pagination.pageSize);
                            }}
                            pagination={{
                                position: ['bottomCenter'],
                                current: tenant.pageNo,
                                pageSize: tenant.pageSize,
                                total: totalCount
                            }}
                            scroll={{ x: 'max-content' }}
                        />
                    </Col>
                </Row>
            </div>
            {
                openUploaDrawer &&
                <AddTenantLogo
                    openUploaDrawer={openUploaDrawer}
                    setOpenUploaDrawer={setOpenUploaDrawer}
                    editData={editData}
                    getTenantList={getTenantList} />
            }
        </>
    );
};

export default DcConguration;