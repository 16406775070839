import { Divider, Drawer, Form, Popconfirm, Row, notification } from "antd";
import FormButton from "../../shared/component/FormButton/FormButton";
import FormInput from "../../shared/component/FormInput/FormInput";
import AppUpload from "../../shared/component/AppUpload/AppUpload";
import { API_SERVICE } from "../../shared/services/api-services";
import uploadIcon from '../../assets/images/upload-icon.svg';
import { useEffect, useState } from "react";
import './dc-configuration.scss'
import FormSelect from "../../shared/component/FormSelect/FormSelect";
import { getEnumList } from "../../shared/Utility/Utility";
import { useSelector } from "react-redux";
import { RootState } from "../../shared/redux/store";

interface IAddTenantLogo {
    openUploaDrawer: any,
    setOpenUploaDrawer: any,
    editData: any,
    getTenantList: any
}

const AddTenantLogo: React.FC<IAddTenantLogo> = (props: IAddTenantLogo) => {

    const { openUploaDrawer, setOpenUploaDrawer, editData, getTenantList } = props
    const [fileData, setfileData] = useState([] as any);
    const [documentData, setDocumentData] = useState(null as any);
    const [docType, setDocType] = useState(null as any);
    const [imageUrl, setImageUrl] = useState(null as any);
    const [tenantForm] = Form.useForm() as any;
    const tenant: any = useSelector((state: RootState) => state.tenant);

    useEffect(() => {
        tenantForm.setFieldsValue({
            tenantName: editData?.businessName,
            businessProductType: editData?.businessProductType,
        })
        if (editData?.tenantLogoName) {
            getDocUpload();
        }
    }, []);

    const getDocUpload = () => {
        let id = editData?.tenantLogoBusinessDocumentId;
        API_SERVICE.getUploadDocument(id)
            .then(({ data }) => {
                setDocumentData(data?.payload?.documentDetails);
                setDocType(data?.payload?.fileExtn);
            }).catch((e: any) => {
                API_SERVICE.handleErrors(e);
            })
    };

    const uploadTenantDetails = async () => {
        if ((fileData?.file?.name?.length ?? 0) == 0) {
            notification.error({ message: "Please Upload Tenant Logo" });
            return;
        }
        const payload = {
            documentUrl: fileData.data ?? "",
        };
        await API_SERVICE.addDocumentTenant(editData?.businessInfoId, payload)
            .then(({ data }: any) => {
                if (data) {
                    setOpenUploaDrawer(false);
                    setfileData([]);
                    getTenantList(tenant.pageNo, tenant.pageSize, tenant.searchParam);
                }
                return;
            })
            .catch((e: any) => {
                API_SERVICE.handleErrors(e);
            })
    };

    const deleteHandler = () => {
        let businessId = editData?.businessInfoId;
        let documentId = editData?.tenantLogoBusinessDocumentId
        API_SERVICE.deleteDocument(businessId, documentId)
            .then((data) => {
                setOpenUploaDrawer(false);
                getTenantList();
            }).catch((e: any) => {
                API_SERVICE.handleErrors(e);
            })
    }

    const updateTenant = (
        tenetId: string,
        params: any
    ) => {
        API_SERVICE.updateTenant(tenetId, params)
            .then(({ data }) => {
                getTenantList(tenant.pageNo, tenant.pageSize, tenant.searchParam);
                setOpenUploaDrawer(false);
            })
            .catch((e: any) => 
                API_SERVICE.handleErrors(e)
            )
    };


    return (
        <>
            <Drawer
                title={"Tenant Configuration"}
                footer={false}
                open={openUploaDrawer}
                onClose={() => setOpenUploaDrawer(false)}
            >
                <Form
                    form={tenantForm}
                    layout="vertical"
                    onFinish={(values: any) => {
                        if(values?.businessProductType) {
                            updateTenant(editData?.businessInfoId, {businessProductType: values?.businessProductType});
                        }
                        if(fileData?.file) {
                            uploadTenantDetails()
                        }
                    }}
                >
                    <FormInput
                        label="Tenant Name"
                        classNameForm="space-input"
                        isBorder={true}
                        name="tenantName"
                        placeholder="Enter Tenant Name"
                        requiredLabel="Tenant Name"
                        isReadonly
                        style={{opacity:"50%", marginBottom:"20px"}}
                    />

                    <FormSelect
                        name="businessProductType"
                        label="Tenant Type"
                        placeholder="Select Tenant Type"
                        isBorder
                        options={getEnumList("BUSINESS_PRODUCT_TYPE")}
                        optionFilterProp="children"
                    />

                    <Form.Item>
                        {editData?.tenantLogoName ? (
                            <div className="replace-background" style={{marginTop:"10px"}}>
                                <img
                                    src={imageUrl ? URL?.createObjectURL(imageUrl) : "data:" + docType + ";base64," + documentData}
                                    alt="icons"
                                    className="before-logo"
                                />
                                <div className="logoManage ">
                                    <AppUpload
                                        acceptType={["image"]}
                                        showUploadList={false}
                                        setImageUrl={setImageUrl}
                                        maxSize={2}
                                        callBack={(value: any) => {
                                            setfileData(value);
                                        }}
                                    >
                                        <p className="repleaceText">
                                            Replace Logo
                                        </p>
                                    </AppUpload>
                                    <Divider type="vertical" className="divider-tenant" />
                                    <Popconfirm placement="topRight" title="Are you sure to Remove Logo?" okText="Yes" cancelText="No" className="remove" onConfirm={deleteHandler}>
                                        <span className="repleaceText">
                                            Remove Logo
                                        </span>
                                    </Popconfirm>
                                </div>
                            </div>
                        ) : (
                            <AppUpload
                                className="upload-wrapper upload-wrapper-bg-white"
                                acceptType={["image"]}
                                showUploadList={false}
                                setImageUrl={setImageUrl}
                                maxSize={2}
                                callBack={(value: any) => {
                                    setfileData(value);
                                }}
                            >
                                <div className="background-logo">
                                    {imageUrl ? (
                                        <img src={URL?.createObjectURL(imageUrl)} alt="" className="upload-logo" />
                                    ) : (
                                        <p style={{ marginTop: '28px' }}>
                                            <img src={uploadIcon} />{" "}
                                            Upload Logo
                                        </p>
                                    )}
                                </div>

                            </AppUpload>
                        )}
                        <div>Note: Required Dimensions - 485px X 90px <br />
                            <span>Format: JPG, PNG</span> <br />
                            <span>Size: Max 2MB</span>
                        </div>
                    </Form.Item>
                    
                    <Row className='step-button end'>
                        <FormButton
                            size="large"
                            label="Save"
                            type="primary"
                            htmlType="submit"
                            // disabled={!fileData?.file}
                        />
                    </Row>
                </Form>
            </Drawer>
        </>
    )
}
export default AddTenantLogo;