import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../dashboard/Dashboard";
import Properties from "../properties/Properties";
import AppContent from "../appConetent/App-content";
import Reports from "../reports/Report";
import {
  PATH_APP_CONTENT,
  PATH_COUPONS,
  PATH_DASHBOARD,
  PATH_DC_CONFIGURATION,
  PATH_PRICING,
  PATH_PRODUCT,
  PATH_PROPERTIES,
  PATH_REPORTS,
  PATH_TENANT_ASSIGN,
  PATH_DELETE_CASES,
  PATH_MANAGE_CATEGORY,
  PATH_MANAGE_DOCUMENT_TYPE,
  PATH_DISPOSITION,
  PATH_DECISIONING_AUDIT,
  PATH_EMBEDDING_IFRAME,
  PATH_PRODUCT_PLAN,
} from "./RouteConstants";
import AssignDC from "../AssignDC/AssignDC";
import DcConguration from "../DcConguration/Dc-configuration";
import Coupons from "../Coupons/Coupons";
import DeleteCases from "../deleteCases/DeleteCases";
import Pricing from "../pricing/Pricing";
import ManageCategory from "../Document/ManageCategory/ManageCategory";
import ManageDocumentType from "../Document/ManageDocumentType/ManageDocumentType";
import Products from "../MasterProperties/Products/Products";
import Disposition from "../Disposition/Disposition";
import DecisioningAudit from "../DecisioningAudit/DecisioningAudit";
import EmbeddingIframe from "../EmbeddingIframe";
import ProductPlan from "../MasterProperties/ProductPlan/ProductPlan";


type Props = {};

const AppRoutes: React.FunctionComponent<Props> = () => {
  return (
    <Routes>
      <Route path={PATH_EMBEDDING_IFRAME} element={<EmbeddingIframe />} />
      <Route path={PATH_DASHBOARD} element={<Dashboard />} />
      <Route path={PATH_PROPERTIES} element={<Properties />} />
      <Route path={PATH_TENANT_ASSIGN} element={<AssignDC />} />
      <Route path={PATH_APP_CONTENT} element={<AppContent />} />
      <Route path={PATH_REPORTS} element={<Reports />} />
      <Route path={PATH_DC_CONFIGURATION} element={<DcConguration />} />
      <Route path={PATH_PRODUCT} element={<Products />} />
      <Route path={PATH_COUPONS} element={<Coupons />} />
      <Route path={PATH_DELETE_CASES} element={<DeleteCases />} />
      <Route path={PATH_PRICING} element={<Pricing />} />
      <Route path={PATH_MANAGE_CATEGORY} element={<ManageCategory />} />
      <Route path={PATH_DISPOSITION} element={<Disposition />} />
      <Route path={PATH_DECISIONING_AUDIT} element={<DecisioningAudit />} />
      <Route path={PATH_PRODUCT_PLAN} element={<ProductPlan />} />
      <Route
        path={PATH_MANAGE_DOCUMENT_TYPE}
        element={<ManageDocumentType />}
      />

      <Route path="/*" element={<Navigate to={"/dashboard"} />} />
    </Routes>
  );
};

export default AppRoutes;
