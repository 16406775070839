import React, { useEffect, useState } from "react";
import { AuthConsumer } from "../../shared/contexts/AuthContext";
import {
  Button,
  Divider,
  Drawer,
  Dropdown,
  Layout,
  Space,
  Typography,
} from "antd";
import { DownOutlined, MenuOutlined } from "@ant-design/icons";
import notificationIcon from "../../assets/images/notification.svg";
import profileIcon from "../../assets/images/avatar.svg";
import AppRoutes from "./AppRoutes";
import menuIconRight from "../../assets/images/sidebar/menu-iconRight.svg";
import menuIconLeft from "../../assets/images/sidebar/menu-iconLeft.svg";
import SideBar from "./SideBar/SideBar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../shared/redux/store";
import "./AppLayout.scss";
import { setScreenTitle } from "../../shared/redux/common-reducer";
import { API_SERVICE } from "../../shared/services/api-services";

const { Content, Sider } = Layout;
const { Title } = Typography;

export default function AppLayout({ children }: any) {
  const { screenTitle } = useSelector((state: RootState) => state.common);
  const { decisioningObject } = useSelector((state: RootState) => state.common);
  const { productsObject } = useSelector((state: RootState) => state.common);
  const { offerCalculationObject } = useSelector(
    (state: RootState) => state.common
  );
  const { journeyObject } = useSelector(
    (state: RootState) => state.common
  );
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [showSider, setShowSider] = useState(true);
  const [showSiderMobile, setShowSiderMobile] = useState(false);
  const user = localStorage.getItem("user");
  const fullName = JSON.parse(user as string)?.userDetails?.fullName;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    getEnumTypes();
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const items = [
    {
      key: "profile",
      label: "My Account",
      icon: <img src={profileIcon} height={20} />,
    },
    {
      key: "logout",
      label: "Logout",
      icon: "",
    },
  ];

  const getEnumTypes = () => {
    API_SERVICE.getTypes()
      .then(({ data }) => {
        localStorage.setItem("enumValues", JSON.stringify(data));
      });
  };

  const onClick = ({ key }: any) => {
    if (key === "profile") {
    } else if (key === "logout") {
      localStorage.clear();
      window.location.reload();
    }
  };

  const decisioningTitleList = (title: any, name: any) => {
    return (
      <div>
        <div>
          {decisioningObject.decisionName === undefined &&
          decisioningObject.masterProductId === undefined &&
          decisioningObject.decisionType === undefined &&
          decisioningObject.purpose === undefined &&
          productsObject.productsName === undefined &&
          productsObject.productsCategory === undefined &&
          productsObject.productsType === undefined &&
          productsObject.description === undefined
            ? ""
            : title}
        </div>
        <div style={{ fontWeight: "500" }}>{name}</div>
      </div>
    );
  };

  const renderDecisioningHeader = () => {
    return (
      <>
        {screenTitle == "Decisioning" ? (
          <>
            {decisioningTitleList(
              "Decision Name",
              decisioningObject.decisionName
            )}
            {decisioningTitleList(
              "Master Product",
              decisioningObject.masterProductId
            )}
            {decisioningTitleList(
              "Decision Type",
              decisioningObject.decisionType
            )}
            {decisioningObject.lenderName &&
              decisioningTitleList("Lender Name", decisioningObject.lenderName)}
            {decisioningTitleList("Purpose", decisioningObject.purpose)}
          </>
        ) : (
          ""
        )}
      </>
    );
  };

  const renderDecisioningProductsHeader = () => {
    return (
      <>
        {(productsObject?.productsName?.length ?? 0) > 0 ? (
          <>
            {decisioningTitleList("Product Name", productsObject.productsName)}
            {decisioningTitleList(
              "Product Category",
              productsObject.productsCategory
            )}
            {decisioningTitleList("Product Type", productsObject.productsType)}
            {decisioningTitleList("Description", productsObject.description)}
          </>
        ) : (
          ""
        )}
      </>
    );
  };

  const offerCalculationTitleList = (title: any, name: any) => {
    return (
      <div>
        <div>
          {offerCalculationObject.offerCalculationName === undefined &&
          offerCalculationObject.offerCalculationType === undefined &&
          offerCalculationObject.offerCalculationType === undefined
            ? ""
            : title}
        </div>
        <div style={{ fontWeight: "500" }}>{name}</div>
      </div>
    );
  };

  const renderOfferCalculationHeader = () => {
    return (
      <>
        {(offerCalculationObject?.offerCalculationName?.length ?? 0) > 0 ? (
          <>
            {offerCalculationTitleList(
              "Offer Calculation Name",
              offerCalculationObject.offerCalculationName
            )}
            {offerCalculationTitleList(
              "Lender Business Name",
              offerCalculationObject.lenderBusinessName
            )}
            {offerCalculationTitleList(
              "Offer Calculation Type",
              offerCalculationObject.offerCalculationType
            )}
          </>
        ) : (
          ""
        )}
      </>
    );
  };

  const journeyTitleList = (title: any, name: any) => {
    return (
      <div>
        <div>
          {journeyObject.name === undefined &&
          productsObject.productsName === undefined &&
          journeyObject.description === undefined 
          ? ""
            : title}
        </div>
        <div style={{ fontWeight: "500" }}>{name}</div>
      </div>
    );
  };

  const renderJourneyHeader = () => {
    return (
      <>
        {(journeyObject.name?.length ?? 0) > 0 ? (
          <>
            {journeyTitleList(
              "Journey Name",
              journeyObject.name
            )}
            {decisioningTitleList(
              "Product",
              productsObject.productsName
            )}
            {journeyTitleList(
              "Description",
              journeyObject.description
            )}  
          </>
        ) : (
          ""
        )}
      </>
    );
  };

  const renderHeader = () => {
    return (
      <div className={isMobile ? "app-header mview" : "app-header"}>
        <div className="header">
          {isMobile && (
            <div style={{ marginTop: "10px" }}>
              <MenuOutlined
                style={{ color: "#253f87", fontSize: "20px" }}
                onClick={() => setShowSiderMobile(true)}
              />
            </div>
          )}

          {!isMobile && (
            <>
              <Title level={5}>{screenTitle}</Title>
              {(decisioningObject?.decisionName?.length ?? 0) > 0
                ? renderDecisioningHeader()
                : (offerCalculationObject?.offerCalculationName?.length ?? 0) >
                  0
                ? renderOfferCalculationHeader() 
               : (journeyObject?.name ?.length ?? 0) > 0 
               ? renderJourneyHeader() 
                : renderDecisioningProductsHeader()}
            </>
          )}

          <div className="profile-bar">
            <img src={notificationIcon} alt="" style={{ marginTop: "-5px" }} />
            <Divider
              type="vertical"
              style={{ height: "30px", marginTop: "10px" }}
            />
            <div style={{ width: "100%" }}>
              <Dropdown
                menu={{
                  items,
                  onClick,
                }}
                placement="bottomRight"
                arrow
              >
                <Space>
                  <span>
                    <span>{fullName}</span>
                    <br />
                    <div className="admin">Admin</div>
                  </span>
                  <Space>
                    <img
                      src={profileIcon}
                      alt={"name"}
                      width="40px"
                      height="40px"
                    />
                    <DownOutlined
                      style={{
                        marginRight: "5px",
                        marginBottom: "4px",
                      }}
                    />
                  </Space>
                </Space>
              </Dropdown>
            </div>
          </div>
        </div>
        {isMobile && (
          <Title level={5} style={{ marginLeft: "15px" }}>
            {screenTitle}
          </Title>
        )}
      </div>
    );
  };

  const renderBody = () => {
    return (
      <>
        <Content>
          <AppRoutes />
        </Content>
      </>
    );
  };

  return (
    <AuthConsumer>
      {({ isAuth, apiCreds }) => {
        return (
          <>
            <Layout style={{ minHeight: "100vh" }} className={`desktop-layout`}>
              {isMobile ? (
                <Drawer
                  footer={false}
                  open={showSiderMobile}
                  placement="left"
                  closable={false}
                  width={260}
                  onClose={() => setShowSiderMobile(false)}
                >
                  <div style={{ width: "250px", marginLeft: "-18px" }}>
                    <SideBar key={2} setShowSiderMobile={setShowSiderMobile} />
                  </div>
                </Drawer>
              ) : (
                <Sider collapsed={!showSider}>
                  <>
                    {!showSider ? (
                      <Divider
                        type="vertical"
                        className="sider-divider-vertical"
                      />
                    ) : (
                      <SideBar
                        key={2}
                        setShowSiderMobile={setShowSiderMobile}
                      />
                    )}
                    <img
                      src={showSider ? menuIconLeft : menuIconRight}
                      alt=""
                      className="sider-icon-collapse"
                      onClick={() => setShowSider(!showSider)}
                    />
                  </>
                </Sider>
              )}

              <Layout>
                <div className="content-wrapper">
                  {renderHeader()}
                  {renderBody()}
                </div>
              </Layout>
            </Layout>
          </>
        );
      }}
    </AuthConsumer>
  );
}
